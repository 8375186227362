import { offerRoute, projectFileRoute, projectRoute } from '../facades.ts'
import type { LinkTarget, LinkTargetResolverMap } from './types.ts'

export const linkTargetResolver: {
  [K in keyof LinkTargetResolverMap]: (
    activityTarget: LinkTargetResolverMap[K]['props']
  ) => LinkTarget<LinkTargetResolverMap[K]['routeKey']>
} = {
  feed: (activityTarget) => ({
    name: activityTarget.feed.name,
    idTag: 'project',
    route: projectRoute({ projectId: activityTarget.feed.urn }),
  }),
  file: (activityTarget) => ({
    name: activityTarget.file.name,
    idTag: 'file',
    route: projectFileRoute({
      projectId: activityTarget.project.urn,
      fileId: activityTarget.file.urn,
    }),
  }),
  offer: (activityTarget) => ({
    name: activityTarget.offer.name,
    idTag: 'offer',
    route: offerRoute({ offerId: activityTarget.offer.urn }),
  }),
  package: (activityTarget) => ({
    name: activityTarget.package.name,
    idTag: 'project',
    route: projectRoute({ projectId: activityTarget.package.urn }),
  }),
  project: (activityTarget) => ({
    name: activityTarget.project.name,
    idTag: 'project',
    route: projectRoute({ projectId: activityTarget.project.urn }),
  }),
}
