import type { AnyValue } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { FileDownloadArgsSchema } from './file-download-args.ts'
import { QuickframeTagsSchema } from './quickframe-tags.ts'

export const MetatagInputSchema = z
  .object({
    tags: QuickframeTagsSchema,
    file: FileDownloadArgsSchema,
  })
  .passthrough()

export type MetatagInput = z.infer<typeof MetatagInputSchema>

export const assertMetatagInput = (input: AnyValue): MetatagInput => {
  return MetatagInputSchema.parse(input)
}

export const HealthCheckInputSchema = z
  .object({
    healthCheck: z.boolean(),
  })
  .passthrough()

export type HealthCheckInput = z.infer<typeof HealthCheckInputSchema>

export const isHealthCheckInput = (
  input: AnyValue
): input is HealthCheckInput => {
  return HealthCheckInputSchema.safeParse(input).success
}

export const MetatagLambdaInputSchema = z.union([
  MetatagInputSchema,
  HealthCheckInputSchema,
])

export type MetaTagLambdaInput = z.infer<typeof MetatagLambdaInputSchema>
