'use client'

import { createContext, useCallback, useContext, useId } from 'react'

import { getTestProps, type TestIds } from '../../utils/testing.ts'

type UseModalProps = TestIds & {
  id?: string
  onClose: () => void
  open?: boolean
  suppressUserClose?: boolean
}

function useModal(props: UseModalProps) {
  const autoId = useId()
  const {
    id: idProp,
    dataTestId,
    dataTrackingId,
    onClose,
    open,
    suppressUserClose,
  } = props

  const id = idProp || `modal-${autoId}`

  const handleClose = useCallback(() => {
    if (!suppressUserClose) {
      onClose()
    }
  }, [onClose, suppressUserClose])

  const getDialogProps = useCallback(
    () => ({
      id,
      ...getTestProps({
        dataTestId,
        dataTrackingId,
      }),
    }),
    [id, dataTestId, dataTrackingId]
  )

  const getPanelProps = useCallback(
    () => ({
      id: `${id}-panel`,
      ...getTestProps({
        dataTestId: dataTestId ? `${dataTestId}-panel` : undefined,
        dataTrackingId: dataTrackingId ? `${dataTrackingId}-panel` : undefined,
      }),
    }),
    [id, dataTestId, dataTrackingId]
  )

  const getCloseProps = useCallback(
    () => ({
      id: `${id}-close`,
      onClose,
      ...getTestProps({
        dataTestId: dataTestId ? `${dataTestId}-close` : undefined,
        dataTrackingId: dataTrackingId ? `${dataTrackingId}-close` : undefined,
      }),
    }),
    [id, dataTestId, dataTrackingId, onClose]
  )

  return {
    id,
    getCloseProps,
    getDialogProps,
    getPanelProps,
    onClose: handleClose,
    open,
    suppressUserClose,
    isInModal: true,
  }
}

type UseModalReturn = ReturnType<typeof useModal>

const ModalContext = createContext<UseModalReturn>({
  id: '',
  getCloseProps: () => ({
    'data-testid': undefined,
    'data-tracking-id': undefined,
    id: '',
    onClose: () => null,
  }),
  getDialogProps: () => ({
    'data-testid': undefined,
    'data-tracking-id': undefined,
    id: '',
  }),
  getPanelProps: () => ({
    'data-testid': undefined,
    'data-tracking-id': undefined,
    id: '',
  }),
  open: false,
  suppressUserClose: false,
  onClose: () => null,
  isInModal: false,
})

const useModalContext = () => useContext(ModalContext)

export {
  ModalContext,
  type UseModalProps,
  type UseModalReturn,
  useModal,
  useModalContext,
}
