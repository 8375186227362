import { linkTargetResolver } from '@mntn-dev/app-routing'
import type {
  ReviewStatus,
  ReviewType,
  RoundStatus,
} from '@mntn-dev/domain-types'
import { Feed, getPersonFullName } from '@mntn-dev/ui-components'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const ReviewStatusChanged = ({
  activity: {
    person,
    details: {
      activityType,
      data: {
        reviewKey: { reviewType },
        reviewStatus,
        roundStatus,
        roundNumber,
        target,
      },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'review_status_changed'>) => {
  const i18nPropertyKey = <Property extends 'summary' | 'status'>(
    property: Property
  ):
    | `${typeof activityType}.${ReviewType}.concepting.${Extract<RoundStatus, 'approved' | 'changes_requested'>}.${Property}`
    | `${typeof activityType}.${ReviewType}.${Exclude<ReviewStatus, 'concepting'>}.${Property}` =>
    reviewStatus === 'concepting'
      ? `${activityType}.${reviewType}.concepting.${roundStatus === 'approved' ? 'approved' : 'changes_requested'}.${property}`
      : `${activityType}.${reviewType}.${reviewStatus}.${property}`

  return (
    person && (
      <>
        {
          <Feed.Summary>
            <ActivityTrans
              t={t}
              i18nKey={i18nPropertyKey('summary')}
              values={{
                subject: getPersonFullName(person),
                roundNumber,
                reviewStatus: t(i18nPropertyKey('status')),
                project: target.project,
              }}
            />
          </Feed.Summary>
        }
        <Feed.Annotations>
          <Feed.Tag type={roundStatus === 'approved' ? 'success' : 'error'}>
            {t(i18nPropertyKey('status'))}
          </Feed.Tag>
          <Link
            {...{
              target: linkTargetResolver.project(target),
              t,
              onNavigate,
              currentUrl,
            }}
          />
        </Feed.Annotations>
      </>
    )
  )
}
