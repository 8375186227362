'use client'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { type PropsWithChildren, useState } from 'react'
import { getQueryClient } from './query-client.ts'
import { trpcClientConfig } from './trpc-client-config.ts'
import { trpcReactClient } from './trpc-react-client.ts'

export default function TrpcReactProvider({ children }: PropsWithChildren) {
  const queryClient = getQueryClient()
  const [trpcClient] = useState(() =>
    trpcReactClient.createClient(trpcClientConfig)
  )

  return (
    <trpcReactClient.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
      </QueryClientProvider>
    </trpcReactClient.Provider>
  )
}
