import { type ToastOptions, toast } from 'react-hot-toast'

import { Icon, type IconName } from '../icon/index.ts'
import { Toast, type ToastProps } from './toast.tsx'

const ToastIcon = ({ name }: { name: IconName }) => (
  <Icon name={name} size="lg" />
)

type ShowToastProps = Omit<ToastProps, 'message'>
type ShowThemedToastProps = Omit<ShowToastProps, 'theme' | 'icon'>

type Message = { id: string }

export const showToast = Object.assign(
  (props: ShowToastProps, options: ToastOptions = {}) => {
    toast((message: Message) => <Toast message={message} {...props} />, options)
  },
  {
    success: (props: ShowThemedToastProps, options: ToastOptions = {}) => {
      toast(
        (message: Message) => (
          <Toast
            message={message}
            theme="positive"
            icon={<ToastIcon name="SuccessIcon" />}
            {...props}
          />
        ),
        options
      )
    },

    error: (props: ShowThemedToastProps, options: ToastOptions = {}) => {
      toast(
        (message: Message) => (
          <Toast
            message={message}
            theme="negative"
            icon={<ToastIcon name="ErrorIcon" />}
            {...props}
          />
        ),
        options
      )
    },

    warning: (props: ShowThemedToastProps, options: ToastOptions = {}) => {
      toast(
        (message: Message) => (
          <Toast
            message={message}
            theme="caution"
            icon={<ToastIcon name="WarningIcon" />}
            {...props}
          />
        ),
        options
      )
    },

    info: (props: ShowThemedToastProps, options: ToastOptions = {}) => {
      toast(
        (message: Message) => (
          <Toast
            message={message}
            theme="info"
            icon={<ToastIcon name="InfoIcon" />}
            {...props}
          />
        ),
        options
      )
    },
  }
)
