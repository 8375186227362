'use client'

import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react'

import type { AnyRoute } from '@mntn-dev/app-routing'
import { LoadingModal } from '@mntn-dev/ui-components'
import { usePathname, useRouter } from 'next/navigation'
import { RouterContext } from './router-context.ts'
import type { PushRouteOptions } from './types.ts'

export const RouterProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const pathname = usePathname()
  const historyStack = useRef<string[]>([])

  // biome-ignore lint/correctness/useExhaustiveDependencies: pathname is a trigger
  useEffect(() => {
    setLoading(false)
  }, [pathname])

  // Exposing a legacy entrypoint for router.push for things that use string URLS, like a bunch of things in onboarding.
  const pushUrl = useCallback(
    (url: string, overrides?: Partial<PushRouteOptions>) => {
      const options = { showLoading: true, ...(overrides ?? {}) }

      if (options.showLoading) {
        setLoading(true)
      }

      historyStack.current.push(url)
      router.push(url)
    },
    [router]
  )

  // The main entrypoint for router.push given a route object.
  const push = useCallback(
    (route: AnyRoute, overrides?: Partial<PushRouteOptions>) => {
      pushUrl(route.toRelativeUrl(), overrides)
    },
    [pushUrl]
  )

  const back = useCallback(
    (count?: number) => {
      for (let i = 0; i < (count ?? 1); i++) {
        historyStack.current.pop()
        router.back()
      }
    },
    [router]
  )

  const replace = useCallback(
    (url: string) => {
      router.replace(url)
    },
    [router]
  )

  const refresh = useCallback(() => {
    router.refresh()
  }, [router])

  const hasHistory = useCallback(() => historyStack.current.length > 0, [])

  const inHistory = useCallback((route: AnyRoute) => {
    const index = historyStack.current.indexOf(route.toRelativeUrl())
    if (!index) {
      return false
    }
    return historyStack.current.length - index - 1
  }, [])

  const backUntilOrPush = (route: AnyRoute) => {
    const depth = inHistory(route)
    if (depth) {
      back(depth)
    } else {
      push(route)
    }
  }

  const backOrPush = (route: AnyRoute) => {
    if (hasHistory()) {
      back()
    } else {
      push(route)
    }
  }

  return (
    <RouterContext.Provider
      value={{
        pushUrl,
        push,
        back,
        replace,
        refresh,
        hasHistory,
        inHistory,
        backUntilOrPush,
        backOrPush,
      }}
    >
      {children}
      {loading && <LoadingModal open={true} />}
    </RouterContext.Provider>
  )
}
