import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  DeliverableIdSchema,
  FileIdSchema,
  ProjectIdSchema,
  ProjectServiceIdSchema,
} from './unique-id.models.ts'

/**
 * DeliverableCategory
 */
export const [
  DeliverableCategories,
  DeliverableCategorySchema,
  DeliverableCategoryEnum,
] = EnumBuilder('video', 'file', 'archive')
export type DeliverableCategory = z.infer<typeof DeliverableCategorySchema>

/**
 * VideoAspectRatio
 */
export const [VideoAspectRatios, VideoAspectRatioSchema, VideoAspectRatioEnum] =
  EnumBuilder('1x1', '4x5', '9x16', '16x9')
export type VideoAspectRatio = z.infer<typeof VideoAspectRatioSchema>

/**
 * VideoCutType
 */
export const [VideoCutTypes, VideoCutTypeSchema, VideoCutTypeEnum] =
  EnumBuilder('hero-cut', 'concept', 'cut-down', 'variation')
export type VideoCutType = z.infer<typeof VideoCutTypeSchema>

/**
 * VideoDuration
 * TODO: Consider using a more specific type for duration, like: z.union([z.literal(15), z.literal(30)])
 */
export const VideoDurationFixedSchema = z.number().int().positive()
export type VideoDurationFixed = z.infer<typeof VideoDurationFixedSchema>

export const VideoDurationRangeSchema = z
  .object({
    min: VideoDurationFixedSchema,
    max: VideoDurationFixedSchema,
  })
  .refine(({ min, max }) => min <= max, 'min must be less than or equal to max')
export type VideoDurationRange = z.infer<typeof VideoDurationRangeSchema>

export const VideoDurationOptionsSchema = z.union([
  VideoDurationFixedSchema,
  VideoDurationRangeSchema,
])
export type VideoDurationOptions = z.infer<typeof VideoDurationOptionsSchema>

export const ArrayOfVideoDurationOptionsSchema = z
  .array(VideoDurationOptionsSchema)
  .nonempty()
export type ArrayOfVideoDurationOptions = z.infer<
  typeof ArrayOfVideoDurationOptionsSchema
>

export const VideoDurationSchema = z.union([
  VideoDurationOptionsSchema,
  ArrayOfVideoDurationOptionsSchema,
])
export type VideoDuration = z.infer<typeof VideoDurationSchema>

export function isVideoDurationRange(
  duration: VideoDuration
): duration is VideoDurationRange {
  return VideoDurationRangeSchema.safeParse(duration).success
}

export function isVideoDurationFixed(
  duration: VideoDuration
): duration is VideoDurationFixed {
  return VideoDurationFixedSchema.safeParse(duration).success
}

export function isVideoDurationArray(
  duration: VideoDuration
): duration is ArrayOfVideoDurationOptions {
  return ArrayOfVideoDurationOptionsSchema.safeParse(duration).success
}

/**
 * ChannelId
 */
export const [ChannelIds, ChannelIdSchema, ChannelIdEnum] = EnumBuilder(
  'facebook',
  'tiktok',
  'instagram',
  'ctv',
  'social'
)
export type ChannelId = z.infer<typeof ChannelIdSchema>

export const DeliverableNameSchema = z.string().min(1)

export const VideoDeliverableDetailsSchema = z.object({
  name: DeliverableNameSchema.optional(),
  category: DeliverableCategorySchema.extract(['video']),
  cut: VideoCutTypeSchema,
  duration: VideoDurationSchema,
  channel: ChannelIdSchema.optional(),
  aspectRatio: VideoAspectRatioSchema.optional(),
})

export type VideoDeliverableDetails = z.infer<
  typeof VideoDeliverableDetailsSchema
>

export function isVideoDeliverable(
  deliverable: DeliverableDetails
): deliverable is VideoDeliverableDetails {
  return VideoDeliverableDetailsSchema.safeParse(deliverable).success
}

export const FileDeliverableDetailsSchema = z.object({
  category: DeliverableCategorySchema.extract(['file']),
  name: DeliverableNameSchema.optional(),
})

export type FileDeliverableDetails = z.infer<
  typeof FileDeliverableDetailsSchema
>

export const ArchiveDeliverableDetailsSchema = z.object({
  category: DeliverableCategorySchema.extract(['archive']),
  name: DeliverableNameSchema.optional(),
})

export type ArchiveDeliverableDetails = z.infer<
  typeof ArchiveDeliverableDetailsSchema
>

export const DeliverableDetailsSchema = z.discriminatedUnion('category', [
  VideoDeliverableDetailsSchema,
  FileDeliverableDetailsSchema,
  ArchiveDeliverableDetailsSchema,
])

export type DeliverableDetails = z.infer<typeof DeliverableDetailsSchema>

/**
 * DeliverableReviewLevel
 */
export const [
  DeliverableReviewLevels,
  DeliverableReviewLevelSchema,
  DeliverableReviewLevelEnum,
] = EnumBuilder('none', 'primary', 'secondary')
export type DeliverableReviewLevel = z.infer<
  typeof DeliverableReviewLevelSchema
>

/**
 * DeliverableDomainSelectModel
 */
export const DeliverableDomainSelectModelSchema = z.object({
  deliverableId: DeliverableIdSchema,
  projectId: ProjectIdSchema,
  projectServiceId: ProjectServiceIdSchema,
  details: DeliverableDetailsSchema,
  reviewLevel: DeliverableReviewLevelSchema,
  fileId: FileIdSchema.optional(),
})

export type DeliverableDomainSelectModel = z.infer<
  typeof DeliverableDomainSelectModelSchema
>

/**
 * DeliverableDomainInsertModel
 */
export const DeliverableDomainInsertModelSchema =
  DeliverableDomainSelectModelSchema.omit({ fileId: true }).partial({
    deliverableId: true,
  })

export type DeliverableDomainInsertModel = z.infer<
  typeof DeliverableDomainInsertModelSchema
>

/**
 * DeliverableDomainUpdateModel
 */
export const DeliverableDomainUpdateModelSchema =
  DeliverableDomainSelectModelSchema.pick({
    fileId: true,
  }).partial()

export type DeliverableDomainUpdateModel = z.infer<
  typeof DeliverableDomainUpdateModelSchema
>

/**
 * DeliverableDomainSelectModelFilter
 */
export const DeliverableDomainSelectModelFiltersSchema = z
  .object({
    deliverableId: ModelFilterSchema(DeliverableIdSchema),
    projectId: ModelFilterSchema(ProjectIdSchema),
    projectServiceId: ModelFilterSchema(ProjectServiceIdSchema),
    fileId: ModelFilterSchema(FileIdSchema),
    reviewLevel: ModelFilterSchema(DeliverableReviewLevelSchema),
  })
  .partial()

export type DeliverableDomainSelectModelFilters = z.infer<
  typeof DeliverableDomainSelectModelFiltersSchema
>
