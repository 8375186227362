import type { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'

export const [ActivityTypes, ActivityTypeSchema, ActivityTypeEnum] =
  EnumBuilder(
    'agreement_terms_accepted',
    'comment_created',
    'pre_production_brand_feedback_submitted',
    'pre_production_maker_proposal_submitted',
    'project_created',
    'project_file_added',
    'project_not_matched',
    'project_offer_expired',
    'project_offer_pending',
    'project_offers_sent',
    'project_service_added',
    'project_status_changed',
    'review_status_changed',
    'service_note_added'
  )

export type ActivityType = z.infer<typeof ActivityTypeSchema>
