import { FileStorageKeySchema, NameSchema } from '@mntn-dev/domain-types'
import { z } from 'zod'
import { FileResourceTypeSchema } from '../resource.ts'

export const FileDownloadArgsSchema = z.object({
  fileStorageKey: FileStorageKeySchema,
  fileResourceType: FileResourceTypeSchema,
  fileName: NameSchema,
})

export type FileDownloadArgs = z.infer<typeof FileDownloadArgsSchema>
