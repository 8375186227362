import { afterLast } from '@mntn-dev/utilities'
import type { SuccessEventInfo } from './upload-success-event.ts'

export const getFileExtension = (
  successEventInfo: Pick<
    SuccessEventInfo,
    'original_extension' | 'format' | 'public_id'
  >
) => {
  return (
    successEventInfo.format ||
    afterLast(successEventInfo.public_id, '.') ||
    successEventInfo.original_extension ||
    ''
  )
}
