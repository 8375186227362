import { useTranslation } from '@mntn-dev/i18n'
import { SystemUserId } from '@mntn-dev/session-manager'
import { Avatar, Feed } from '@mntn-dev/ui-components'
import type { PropsWithChildren } from 'react'
import { UnhandledUnionError } from '../../utilities/src/errors/unhandled-union-error.ts'
import {
  AgreementTermsAccepted,
  CommentCreated,
  PreProductionBrandFeedbackSubmitted,
  PreProductionMakerProposalSubmitted,
  ProjectCreated,
  ProjectFileAdded,
  ProjectNotMatched,
  ProjectOfferExpired,
  ProjectOfferPending,
  ProjectOffersSent,
  ProjectServiceAdded,
  ProjectStatusChanged,
  ReviewStatusChanged,
  ServiceNoteAdded,
} from './components/activity-details/index.ts'
import type { ActivityProps } from './types.ts'

export const Activity = ({
  activity,
  image,
  indicator,
  ...props
}: ActivityProps) => {
  const { activityId, actorId, timestamp, details } = activity
  const { t, i18n } = useTranslation('activity')
  const isSystemActivity = actorId === SystemUserId

  const ActivityContainer = ({
    activity: { person },
    children,
  }: PropsWithChildren<Pick<ActivityProps, 'activity'>>) => (
    <Feed.Item
      avatar={
        person && !isSystemActivity ? (
          <Avatar.User person={person} />
        ) : (
          <Avatar.Icon name="ExclamationIcon" />
        )
      }
      key={activityId}
      timestamp={timestamp}
      languageId={i18n.language}
      indicator={indicator}
    >
      {children}
    </Feed.Item>
  )

  switch (details.activityType) {
    case 'agreement_terms_accepted':
      return (
        <ActivityContainer {...{ activity }}>
          <AgreementTermsAccepted
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'comment_created':
      return (
        <ActivityContainer {...{ activity }}>
          <CommentCreated
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'pre_production_brand_feedback_submitted':
      return (
        <ActivityContainer {...{ activity }}>
          <PreProductionBrandFeedbackSubmitted
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'pre_production_maker_proposal_submitted':
      return (
        <ActivityContainer {...{ activity }}>
          <PreProductionMakerProposalSubmitted
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_created':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectCreated
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_file_added':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectFileAdded
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_offer_expired':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectOfferExpired
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_offer_pending':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectOfferPending
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_offers_sent':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectOffersSent
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_not_matched':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectNotMatched
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_service_added':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectServiceAdded
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_status_changed':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectStatusChanged
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'review_status_changed':
      return (
        <ActivityContainer {...{ activity }}>
          <ReviewStatusChanged
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'service_note_added':
      return (
        <ActivityContainer {...{ activity }}>
          <ServiceNoteAdded
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    default:
      throw new UnhandledUnionError(details)
  }
}
