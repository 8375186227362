'use client'

import { useEffect, useRef } from 'react'

import type {
  ChannelName,
  RealtimeMessageHandlerFunction,
} from '@mntn-dev/realtime-updates-types'

import { realtimeClientService } from './realtime-client-service.ts'
import { realtimeUpdateLogger } from './realtime-update-logger.ts'
import type { RealtimeSchema } from './schemas.ts'

const useHandleAllMessages = (
  channelName: ChannelName | undefined,
  messageHandler: RealtimeMessageHandlerFunction<RealtimeSchema>
) => {
  const channel = useRef<
    ReturnType<typeof realtimeClientService.getChannel> | undefined
  >(undefined)

  if (
    channelName &&
    (!channel.current || channel.current.name !== channelName)
  ) {
    channel.current = realtimeClientService.getChannel(channelName)
  }

  useEffect(() => {
    const messageHandlerWrapper: RealtimeMessageHandlerFunction<
      RealtimeSchema
    > = (message) => {
      realtimeUpdateLogger.debug(
        '---> Handling realtime message (useHandleAllMessages)',
        { message }
      )
      return messageHandler(message)
    }

    if (channel.current) {
      realtimeClientService.handleAllMessages(
        channel.current,
        messageHandlerWrapper
      )
    }

    return () => {
      if (channel.current) {
        channel.current.unsubscribe()
        channel.current.detach()
      }
    }
  })

  return channel.current
}

export { useHandleAllMessages }
