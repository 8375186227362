'use client'

import { makeRange } from '@mntn-dev/utilities'

import { Radio, RadioGroup } from '@headlessui/react'
import { forwardRef } from 'react'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import {
  type UseFormFieldControlProps,
  useFormFieldControl,
} from '../form-field/use-form-field-control.ts'
import { Icon } from '../icon/icon.tsx'

type RatingStarsProps = Readonly<
  UseFormFieldControlProps<HTMLElement> &
    TestIds & {
      ariaLabel: string
      total: number
      defaultValue?: number
      onChange?: (rating: number) => void
    }
>

const RatingStars = forwardRef<HTMLElement, RatingStarsProps>(
  (
    {
      dataTestId,
      dataTrackingId,
      disabled,
      id,
      hasError: hasErrorProp,
      ariaLabel,
      total,
      readOnly,
      defaultValue,
      onChange,
    }: RatingStarsProps,
    ref
  ) => {
    /**
     * Options are reversed and flex-row-reversed in css so we can use peer styling when hovering
     * The ~ selector selects siblings after, not before. Reversing gets around that.
     */
    const reversedOptions = makeRange(total)
      .map((option) => option + 1)
      .reverse()

    const {
      hasError: _,
      hasSuccess: __,
      hasWarning: ___,
      ...fieldProps
    } = useFormFieldControl<HTMLElement>({
      disabled,
      id,
      hasError: hasErrorProp,
      readOnly,
      dataTestId,
      dataTrackingId,
    })

    return (
      <RadioGroup
        className="flex flex-row-reverse justify-end gap-1.5"
        ref={ref}
        defaultValue={defaultValue}
        onChange={onChange}
        {...fieldProps}
      >
        {({ value }) => (
          <>
            {reversedOptions.map((option) => (
              <Radio
                key={option}
                value={option}
                aria-label={`${option} ${ariaLabel}`}
                className="cursor-pointer peer peer-hover:drop-shadow-glow-blue-light hover:drop-shadow-glow-blue-light transition ease-in-out duration-300"
                {...getTestProps({
                  dataTestId: `${dataTestId}-${option}`,
                  dataTrackingId: `${dataTrackingId}-${option}`,
                })}
              >
                <Icon
                  size="2xl"
                  name="StarIcon"
                  fill={!!value && value >= option ? 'solid' : 'outline'}
                  color="info"
                />
              </Radio>
            ))}
          </>
        )}
      </RadioGroup>
    )
  }
)

export { RatingStars, type RatingStarsProps }
