import type { ProjectId, ProjectServiceId } from '@mntn-dev/domain-types'
import { register } from './register.ts'
import type { EmptyObject } from 'type-fest'

const routes = [
  register('/').toRoute({}),
  register('/api/auth/logout').toRoute({}),
  register('/dashboard').toRoute({}),
  register('/login').toRoute({}),
  register('/offers').toRoute({}),
  register('/offers/:offerId').toRoute<{ offerId: string }>({}),
  register('/onboarding').toRoute({}),
  register('/packages').toRoute({}),
  register('/packages/:packageId').toRoute<{ packageId: string }>({}),
  register('/packages/services').toRoute({}),
  register('/profile').toRoute({}),
  register('/projects').toRoute({}),
  register('/projects/:projectId').toRoute<{ projectId: string }>({}),
  register('/projects/:projectId/services/:projectServiceId').toRoute<{
    projectId: ProjectId
    projectServiceId: ProjectServiceId
  }>({}),
  register('/projects/:projectId/match').toRoute<{ projectId: string }>({}),
  register('/projects/:projectId/match/maker/:userId').toRoute<{
    projectId: string
    userId: string
  }>({}),
  register('/projects/:projectId/browse').toRoute<{
    projectId: string
  }>({}),
  register(
    '/projects/:projectId/review/post-production/:deliverableId'
  ).toRoute<{
    projectId: string
    deliverableId: string
  }>({}),
  register('/projects/:projectId/files').toRoute<{
    projectId: string
  }>({}),
  register('/projects/:projectId/files/:fileId').toRoute<{
    projectId: string
    fileId: string
  }>({}),
  register('/projects/new').toRoute({}),
  register('/settings').toRoute({}),
  register('/users').toRoute({}),
  register('/users/:userId').toRoute<{ userId: string }>({}),

  // Testing
  register('/foo').toRoute<EmptyObject, { baz: string }>({}),
  register('/foo/:fooId').toRoute<{ fooId: string }, { baz: string }>({}),
  register('/foo/:fooId/bar/:barId').toRoute<
    { fooId: string; barId: string },
    { baz: string; qux: string; corge?: string }
  >({}),
]

export default routes
