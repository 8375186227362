'use client'

import type { PropsWithChildren } from 'react'

type ConfirmationModalFooterProps = PropsWithChildren

export const ConfirmationModalFooter = ({
  children,
}: ConfirmationModalFooterProps) => {
  return <div className="flex justify-end gap-6 pt-12">{children}</div>
}
