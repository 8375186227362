import type { ThemeTextColor } from '@mntn-dev/ui-theme'
import { Children, Fragment, type PropsWithChildren } from 'react'
import type { HeadingSize } from '../../types'
import { Heading } from '../heading'

const Breadcrumbs = ({
  children,
  dividerColor = 'info',
  dividerSize = '2xl',
}: PropsWithChildren<{
  dividerColor?: ThemeTextColor
  dividerSize?: HeadingSize
}>) => {
  return (
    <div className="[&>*]:inline">
      {Children.map(children, (child, index) => (
        <Fragment key={`breadcrumb-item-${index + 1}`}>
          {child && index > 0 && (
            <Heading
              className="inline p-1"
              fontSize={dividerSize}
              textColor={dividerColor}
            >
              /
            </Heading>
          )}
          {child}
        </Fragment>
      ))}
    </div>
  )
}

export { Breadcrumbs }
