import { FileIdSchema, UrlSchema } from '@mntn-dev/domain-types'
import type { ImageTransformationOptions } from '@mntn-dev/files-shared'
import type { Person } from '@mntn-dev/ui-components'
import { satisfiesSchema } from '@mntn-dev/utility-types'
import { getFileImageProxyUrl } from '~/utils/client/file-utilities.ts'

export const usePersonImageUrlInterceptor = (
  person: Person,
  options?: ImageTransformationOptions
) => {
  /**
   * todo: This will be refactored in https://mntn.atlassian.net/browse/QF-3018
   */

  const fileId = satisfiesSchema(FileIdSchema, person.avatarFileId)
    ? person.avatarFileId
    : satisfiesSchema(FileIdSchema, person.imageUrl)
      ? person.imageUrl
      : undefined

  if (satisfiesSchema(UrlSchema, person.imageUrl) || !fileId) {
    return { person }
  }

  const imageUrl = getFileImageProxyUrl({
    fileId,
    options: {
      height: 200,
      width: 200,
      gravity: 'custom',
      crop: 'thumb',
      ...options,
    },
  })

  return {
    person: {
      ...person,
      imageUrl,
    },
  }
}
