import { UserId } from '@mntn-dev/domain-types'
import { useRealtimeSubscription } from '@mntn-dev/realtime-updates-service'
import { trpcReactClient } from '~/app/_trpc/trpc-react-client.ts'
import { useMe } from '~/hooks/secure/use-me.ts'
import { useRefetchNotifications } from './use-refetch-notifications.ts'

export const useNotificationsQuery = () => {
  const { me } = useMe()

  const refetchNotifications = useRefetchNotifications()

  const { data: notifications } =
    trpcReactClient.notifications.getNotificationsForUser.useQuery(
      {
        userId: me?.userId ?? '',
      },
      {
        enabled: !!me,
      }
    )

  useRealtimeSubscription(
    `user:${me?.userId ?? UserId()}`,
    'notification.created',
    async () => {
      await refetchNotifications()
    }
  )

  return {
    notifications,
    refetchNotifications,
    unreadNotificationCount: notifications?.filter(
      (notification) => notification.status === 'unread'
    ).length,
  }
}
