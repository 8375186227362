'use client'

import { useIntersectionObserver, usePrevious } from '@mntn-dev/ui-utilities'
import { mergeRefs } from '@mntn-dev/utilities'
import { type RefObject, useEffect } from 'react'

export function useScrollIntoView<TElement extends HTMLElement>({
  scroll,
  innerRef,
}: { scroll: boolean | undefined; innerRef: RefObject<TElement | undefined> }) {
  const { isIntersecting, ref: intersectionObserverRef } =
    useIntersectionObserver({
      threshold: 0.5,
    })

  const previousScroll = usePrevious(scroll)

  useEffect(() => {
    if (
      scroll &&
      !previousScroll &&
      innerRef &&
      innerRef.current &&
      !isIntersecting
    ) {
      innerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [scroll, previousScroll, innerRef, isIntersecting])

  return {
    mergedRef: mergeRefs(innerRef, intersectionObserverRef),
  }
}
