import { runningOnServer } from '@mntn-dev/utilities'
import type { ExactObject } from '@mntn-dev/utility-types'
import type { EmptyObject } from 'type-fest'
import type { UriKind } from './types/url-types.ts'

export type RouteRegistration<
  TPattern extends string,
  TRouteParams extends {} = EmptyObject,
  TQueryParams extends {} = EmptyObject,
  TRouteAttributes extends {} = EmptyObject,
> = {
  pattern: TPattern
  params: (
    routeParams: ExactObject<TRouteParams>
  ) => RouteRegistration<TPattern, TRouteParams, TQueryParams, TRouteAttributes>
  attributes: TRouteAttributes
  query: (
    queryParams: ExactObject<TQueryParams>
  ) => RouteRegistration<TPattern, TRouteParams, TQueryParams, TRouteAttributes>
  toRelativeUrl: () => string
  toAbsoluteUrl: () => string
  toUrl: (uriKind: UriKind) => string
  isMatch: (otherPath: string) => boolean
}

export function register<TPattern extends string>(pattern: TPattern) {
  return {
    toRoute: <
      TRouteParams extends {} = EmptyObject,
      TQueryParams extends {} = EmptyObject,
      TRouteAttributes extends {} = EmptyObject,
    >(
      attributes: TRouteAttributes
    ): [
      TPattern,
      () => RouteRegistration<
        TPattern,
        TRouteParams,
        TQueryParams,
        TRouteAttributes
      >,
    ] => {
      const routeBuilder: () => RouteRegistration<
        TPattern,
        TRouteParams,
        TQueryParams,
        TRouteAttributes
      > = () => {
        let path: string = pattern
        const toRelativeUrl = () => path
        const toAbsoluteUrl = () =>
          // TODO: Move getClientBaseUrl function from magicsky to somewhere app & env common and hook into it from here.
          // Maybe a new package called app-common?
          // Ensure Storybook Activity still works. It seems to have issue with env from @mntn-dev/env
          `${runningOnServer() ? process.env.BASE_URL : window.location.origin}${path}`

        const route: RouteRegistration<
          TPattern,
          TRouteParams,
          TQueryParams,
          TRouteAttributes
        > = {
          pattern,
          params: (routeParams) => {
            path = Object.keys(routeParams).reduce<string>((path, key) => {
              return path.replace(
                `:${key}`,
                `${routeParams[key as keyof ExactObject<TRouteParams>]}`
              )
            }, path)

            return route
          },
          query: (queryParams) => {
            path = `${path}?${new URLSearchParams(
              Object.entries(queryParams)
                .map(([key, value]) => [
                  key,
                  value === undefined ? '' : String(value),
                ])
                .sort()
            ).toString()}`

            return route
          },
          attributes,
          toRelativeUrl,
          toAbsoluteUrl,
          toUrl: (uriKind) =>
            uriKind === 'relative' ? toRelativeUrl() : toAbsoluteUrl(),
          isMatch: (otherPath: string) => {
            const otherPathSegments = otherPath.split('/')
            const thisPathSegments = path.split('/')

            if (otherPathSegments.length !== thisPathSegments.length) {
              return false
            }

            return thisPathSegments.reduce<boolean>((result, _, index) => {
              if (result) {
                return (
                  thisPathSegments[index]?.startsWith(':') ||
                  otherPathSegments[index] === thisPathSegments[index]
                )
              }
              return false
            }, true)
          },
        }

        return route
      }

      return [pattern, routeBuilder]
    },
  }
}
