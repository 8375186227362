import { trpcReactClient } from '~/app/_trpc/trpc-react-client.ts'

export function useRefetchBuilder() {
  const _utils = trpcReactClient.useUtils()
  const promises: (false | Promise<void> | undefined)[] = []

  const refetchBuilder = {
    include: (
      input: (utils: typeof _utils) => false | Promise<void> | undefined,
      options?: { disabled?: boolean }
    ) => {
      if (options?.disabled || input === undefined) {
        return refetchBuilder
      }

      promises.push(input(_utils))

      return refetchBuilder
    },
    fetch: async () => {
      await Promise.all(promises)
    },
  }

  return refetchBuilder
}
