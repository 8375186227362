import { z } from 'zod'

import { EnumBuilder, type NonEmptyArray } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { OpaqueSchema } from '../unique-id-builder.ts'
import {
  DescriptionSchema,
  NameSchema,
  NonEmptyStringSchema,
  TimestampSchema,
} from './property.models.ts'
import {
  FileIdSchema,
  OnboardingUrnSchema,
  PackageUrnSchema,
  ProjectServiceUrnSchema,
  ProjectUrnSchema,
  ReviewUrnSchema,
  UserIdSchema,
  UserUrnSchema,
} from './unique-id.models.ts'

/**
 * FileUploadStatus
 */
export const [
  FileUploadStatuses,
  FileUploadStatusSchema,
  FileUploadStatusEnum,
] = EnumBuilder('unknown', 'initiated', 'complete')
export type FileUploadStatus = z.infer<typeof FileUploadStatusSchema>

/**
 * FileStatus
 */
export const [FileStatuses, FileStatusSchema, FileStatusEnum] = EnumBuilder(
  'active',
  'archived'
)
export type FileStatus = z.infer<typeof FileStatusSchema>

/**
 * FileCategory
 */
export const [FileCategories, FileCategorySchema, FileCategoryEnum] =
  EnumBuilder('document', 'image', 'raw', 'video', 'audio', 'archive')
export type FileCategory = z.infer<typeof FileCategorySchema>

/**
 * FileMimeType - this could become an enum if we need to validate specific mime types
 */
export const FileMimeTypeSchema = NonEmptyStringSchema(256)

/**
 * FileSize
 */
export const FileSizeSchema = z.number().int().positive()

/**
 * FileTaggingStatus
 */
export const [FileTaggingStatuses, FileTaggingStatusSchema] = EnumBuilder(
  'pending',
  'processing',
  'complete',
  'error'
)
export type FileTaggingState = z.infer<typeof FileTaggingStatusSchema>

/**
 * FolderUrn
 */
export const FolderUrnSchema = z.union([
  OnboardingUrnSchema,
  PackageUrnSchema,
  ProjectUrnSchema,
  ProjectServiceUrnSchema,
  ReviewUrnSchema,
  UserUrnSchema,
])
export type FolderUrn = z.infer<typeof FolderUrnSchema>

type ExtractFolderIdTag<T> = T extends `urn:${infer U}:${string}` ? U : never

type ExtractFolderUrnId<T> = T extends `urn:${string}:${infer I}` ? I : never

export type FolderIdTag = ExtractFolderIdTag<FolderUrn>

export type FolderUrnId = ExtractFolderUrnId<FolderUrn>

// Function to extract FolderIdTag and FolderUrnId from a FolderUrn string
export function parseFolderUrn(urn: FolderUrn): [FolderIdTag, FolderUrnId] {
  const [, folderIdTag, folderUrnId] = FolderUrnSchema.transform(
    (input) => input.split(':') as ['urn', FolderIdTag, FolderUrnId]
  ).parse(urn)

  return [folderIdTag, folderUrnId]
}

/**
 * FileStorageKey
 */
export const FileStorageKeySchema = OpaqueSchema(
  NonEmptyStringSchema(256),
  'file-storage-key'
)
export type FileStorageKey = z.infer<typeof FileStorageKeySchema>
export const FileStorageKey = (s: string) => FileStorageKeySchema.parse(s)

/**
 * FileArea
 */

export const [FileAreas, FileAreaSchema, FileAreaEnum] = EnumBuilder(
  'avatars',
  'packages.examples',
  'projects.thumbnails',
  'projects.assets.images',
  'projects.assets.videos',
  'projects.assets.reference',
  'projects.services.assets.deliverables',
  'projects.services.assets.final',
  'projects.services.assets.reference'
)

export type FileArea = z.infer<typeof FileAreaSchema>

export const PublicFileAreaSchema = FileAreaSchema.extract(['avatars'])
export const PublicFileAreas = FileAreaSchema.options

export type PublicFileArea = z.infer<typeof PublicFileAreaSchema>

export const TaggableFileAreas: NonEmptyArray<FileArea> = [
  'projects.services.assets.final',
]
export const TaggableFileCategories: NonEmptyArray<FileCategory> = ['video']

export const FileDomainSelectModelSchema = z.object({
  fileId: FileIdSchema,
  folderUrn: FolderUrnSchema,
  area: FileAreaSchema,
  name: NameSchema,
  category: FileCategorySchema,
  mimeType: FileMimeTypeSchema,
  size: FileSizeSchema,
  status: FileStatusSchema,
  storageKey: FileStorageKeySchema,
  title: NameSchema.optional(),
  description: DescriptionSchema.optional(),
  ownerId: UserIdSchema,
  taggingStatus: FileTaggingStatusSchema.optional(),
  uploadTimestamp: TimestampSchema,
  uploadStatus: FileUploadStatusSchema,
})

export type FileDomainSelectModel = z.infer<typeof FileDomainSelectModelSchema>

/**
 * FileDomainInsertModel
 */
export const FileDomainInsertModelSchema = FileDomainSelectModelSchema.omit({
  status: true,
  uploadTimestamp: true,
}).partial({
  fileId: true,
  ownerId: true,
})

export type FileDomainInsertModel = z.infer<typeof FileDomainInsertModelSchema>

/**
 * FileDomainUpdateModel
 */
export const FileDomainUpdateModelSchema = z
  .object({
    title: FileDomainSelectModelSchema.shape.title.nullable(),
    description: FileDomainSelectModelSchema.shape.description.nullable(),
    status: FileStatusSchema,
    taggingStatus: FileTaggingStatusSchema,
    uploadStatus: FileUploadStatusSchema,
    folderUrn: FolderUrnSchema,
  })
  .partial()

export type FileDomainUpdateModel = z.infer<typeof FileDomainUpdateModelSchema>

/**
 * FileDomainSelectModelFilter
 */
export const FileDomainSelectModelFiltersSchema = z
  .object({
    fileId: ModelFilterSchema(FileIdSchema),
    area: ModelFilterSchema(FileAreaSchema),
    category: ModelFilterSchema(FileCategorySchema),
    storageKey: ModelFilterSchema(FileStorageKeySchema),
    folderUrn: ModelFilterSchema(FolderUrnSchema),
    search: NonEmptyStringSchema(),
    status: ModelFilterSchema(FileStatusSchema),
    taggingStatus: ModelFilterSchema(FileTaggingStatusSchema),
  })
  .partial()

export type FileDomainSelectModelFilters = z.infer<
  typeof FileDomainSelectModelFiltersSchema
>
