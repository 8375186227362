'use client'

import { cn } from '@mntn-dev/ui-utilities'

import type { LanguageId } from '@mntn-dev/domain-types'
import { useTranslation } from '@mntn-dev/i18n'

import { backgroundImageMap } from '../../../classes/background-image.ts'
import type { BackgroundImage } from '../../../types/background-image.ts'

type ImageCardImageProps = Readonly<{
  image: BackgroundImage
  lng: LanguageId
}>

const ImageCardImage = ({ image, lng }: ImageCardImageProps) => {
  const { t } = useTranslation('background-image')
  return (
    <div
      role="img"
      aria-label={t(image)}
      className={cn(
        `h-[18.75rem] ${backgroundImageMap[image]} bg-cover bg-center bg-no-repeat`
      )}
    />
  )
}

export { ImageCardImage, type ImageCardImageProps }
