import AcceptLanguageParser from 'accept-language'

import { type LanguageId, LanguageIds } from '@mntn-dev/domain-types'
import { fallbackLanguageId } from '../settings.ts'
import { isLanguageId } from './is-language-id.ts'

export const setSupportedLanguages = (ids: LanguageId[] = LanguageIds) => {
  AcceptLanguageParser.languages(Array.from(ids))
}

/**
 * Set supported languages
 */
setSupportedLanguages()

/**
 * Get language from Accept-Language header string
 * If Accept-Language header is not set, return fallback language
 * @param acceptLanguage Accept-Language header string
 * @returns
 */
export const getLanguageFromAcceptLanguage = (
  acceptLanguage: string | null
): LanguageId => {
  const language = AcceptLanguageParser.get(acceptLanguage)
  return isLanguageId(language) ? language : fallbackLanguageId
}
