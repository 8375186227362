import { linkTargetResolver } from '@mntn-dev/app-routing'
import { Feed, type TagType, getPersonFullName } from '@mntn-dev/ui-components'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const ProjectStatusChanged = ({
  activity: {
    person,
    details: {
      activityType,
      data: { target, status },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'project_status_changed'>) =>
  person && (
    <>
      <Feed.Summary>
        <ActivityTrans
          t={t}
          i18nKey={`${activityType}.${status}.summary`}
          values={{
            subject: getPersonFullName(person),
            project: target.project,
          }}
        />
      </Feed.Summary>
      <Feed.Annotations>
        <Feed.Tag type={t(`${activityType}.${status}.tag`) as TagType}>
          {t(`${activityType}.${status}.status`)}
        </Feed.Tag>
        <Link
          {...{
            target: linkTargetResolver.project(target),
            t,
            onNavigate,
            currentUrl,
          }}
        />
      </Feed.Annotations>
    </>
  )
