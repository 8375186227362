import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'

import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  ReviewRoundCountSchema,
  ReviewRoundNumberSchema,
  ReviewStatusSchema,
} from './property.models.ts'
import {
  ProjectIdSchema,
  ProjectServiceIdSchema,
  ReviewIdSchema,
} from './unique-id.models.ts'

/**
 * ReviewVersion
 */
export const ReviewVersion1 = 1
export const ReviewVersion2 = 2

export const ReviewVersionSchema = z.union([
  z.literal(ReviewVersion1),
  z.literal(ReviewVersion2),
])

export type ReviewVersion = z.infer<typeof ReviewVersionSchema>

export const DefaultReviewVersion: ReviewVersion = ReviewVersion1

/**
 * ReviewType
 */
export const [ReviewTypes, ReviewTypeSchema, ReviewTypeEnum] = EnumBuilder(
  'pre_production',
  'post_production'
)
export type ReviewType = z.infer<typeof ReviewTypeSchema>

/**
 * ReviewKey
 */
export const PreProductionReviewKeySchema = z.object({
  reviewType: ReviewTypeSchema.extract(['pre_production']),
  projectId: ProjectIdSchema,
  projectServiceId: ProjectServiceIdSchema,
})

export type PreProductionReviewKey = z.infer<
  typeof PreProductionReviewKeySchema
>

export const PostProductionReviewKeySchema = z.object({
  reviewType: ReviewTypeSchema.extract(['post_production']),
  projectId: ProjectIdSchema,
})

export type PostProductionReviewKey = z.infer<
  typeof PostProductionReviewKeySchema
>

export const ReviewKeySchema = z.discriminatedUnion('reviewType', [
  PreProductionReviewKeySchema,
  PostProductionReviewKeySchema,
])

export type ReviewKey = z.infer<typeof ReviewKeySchema>

/**
 * ReviewDomainSelectModel
 */
export const ReviewDomainSelectModelSchema = z.object({
  reviewId: ReviewIdSchema,
  reviewKey: ReviewKeySchema,
  version: ReviewVersionSchema,
  maxChangeRequests: ReviewRoundCountSchema,
  currentRoundNumber: ReviewRoundNumberSchema,
  status: ReviewStatusSchema,
})

export type ReviewDomainSelectModel = z.infer<
  typeof ReviewDomainSelectModelSchema
>

/**
 * ReviewDomainInsertModel
 */
export const ReviewDomainInsertModelSchema = ReviewDomainSelectModelSchema.omit(
  {
    status: true, // Always inserted as concepting
    currentRoundNumber: true, // Always inserted as 1
  }
).partial({
  reviewId: true,
})

export type ReviewDomainInsertModel = z.infer<
  typeof ReviewDomainInsertModelSchema
>

/**
 * ReviewDomainUpdateModel
 */
export const ReviewDomainUpdateModelSchema = ReviewDomainSelectModelSchema.pick(
  {
    maxChangeRequests: true,
    currentRoundNumber: true,
    status: true,
  }
).partial()

export type ReviewDomainUpdateModel = z.infer<
  typeof ReviewDomainUpdateModelSchema
>

/**
 * ReviewDomainSelectModelFilter
 */
export const ReviewDomainSelectModelFiltersSchema = z
  .object({
    version: ModelFilterSchema(ReviewVersionSchema),
    reviewId: ModelFilterSchema(ReviewIdSchema),
    projectId: ModelFilterSchema(ProjectIdSchema),
    projectServiceId: ModelFilterSchema(ProjectServiceIdSchema),
    reviewType: ModelFilterSchema(ReviewTypeSchema),
    status: ModelFilterSchema(ReviewStatusSchema),
  })
  .partial()

export type ReviewDomainSelectModelFilters = z.infer<
  typeof ReviewDomainSelectModelFiltersSchema
>
