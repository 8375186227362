'use client'

import { useMe } from '~/hooks/secure/use-me.ts'
import { useRefetchBuilder } from '~/hooks/use-refetch-builder.ts'

export const useRefetchNotifications = () => {
  const refetchBuilder = useRefetchBuilder()
  const { me } = useMe()

  return () =>
    me &&
    refetchBuilder
      .include((utils) =>
        utils.notifications.getNotificationsForUser.refetch({
          userId: me.userId,
        })
      )
      .fetch()
}
