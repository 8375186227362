import { linkTargetResolver } from '@mntn-dev/app-routing'
import { Feed, getPersonFullName } from '@mntn-dev/ui-components'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const ServiceNoteAdded = ({
  activity: {
    person,
    details: {
      activityType,
      data: { target, noteType },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'service_note_added'>) =>
  person && (
    <>
      <Feed.Summary>
        <ActivityTrans
          t={t}
          i18nKey={`${activityType}.summary`}
          values={{
            subject: getPersonFullName(person),
            service: target.service,
            noteType,
          }}
        />
      </Feed.Summary>
      <Feed.Annotations>
        <Link
          {...{
            target: linkTargetResolver.project(target),
            t,
            onNavigate,
            currentUrl,
          }}
        />
      </Feed.Annotations>
    </>
  )
