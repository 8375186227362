'use client'

import { Button, type ButtonProps } from '../../buttons/button.tsx'
import { useConfirmationModalContext } from './use-confirmation-modal.tsx'

export const ConfirmationModalButton = (props: ButtonProps) => {
  return <Button {...props} />
}

export const ConfirmationModalCancelButton = (props: ButtonProps) => {
  const { getConfirmationModalProps } = useConfirmationModalContext()
  const { onClose } = getConfirmationModalProps()

  return <Button variant="text" onClick={onClose} {...props} />
}

export const ConfirmationModalConfirmButton = (props: ButtonProps) => {
  const { getConfirmationModalProps } = useConfirmationModalContext()
  const { onConfirm } = getConfirmationModalProps()

  return <Button variant="primary" onClick={onConfirm} {...props} />
}
