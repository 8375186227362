import { Modal, type ModalProps } from '../modal/modal.tsx'
import { LoadingCenter } from './loading-center.tsx'

const LoadingModal = ({
  ...props
}: Omit<ModalProps, 'children' | 'onClose'>) => (
  <Modal {...props} onClose={() => null} backgroundBlur={null}>
    <LoadingCenter />
  </Modal>
)

export { LoadingModal }
