import { useMemo } from 'react'

import { createPermissions } from '@mntn-dev/session-manager'
import { useMe } from './use-me.ts'

function usePermissions() {
  const { me } = useMe()
  const { userType } = me

  const permissions = useMemo(() => createPermissions({ userType }), [userType])

  return permissions
}

export { usePermissions }
