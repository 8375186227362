import { z } from 'zod'

import type { NonEmptyArray } from '@mntn-dev/utility-types'
import { AnyUrnSchema } from '../../types.ts'

export const ActivityLinkTargetSchema = z.object({
  name: z.string(),
  urn: AnyUrnSchema,
})

export type ActivityLinkTarget = z.infer<typeof ActivityLinkTargetSchema>

export const ActivityLinkTargets = <Keys extends string>(
  ...keys: NonEmptyArray<Keys>
) => {
  const ActivityLinkKeySchema = z.enum(keys)

  return z.object({
    target: z
      .record(ActivityLinkKeySchema, ActivityLinkTargetSchema)
      .refine((target): target is Required<Record<Keys, ActivityLinkTarget>> =>
        ActivityLinkKeySchema.options.every((key) => key in target)
      ),
  })
}
