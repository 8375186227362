'use client'

import { Combobox, ComboboxOptions } from '@headlessui/react'
import { themeBackgroundBlurMap, themeBackgroundMap } from '@mntn-dev/ui-theme'
import React, {
  useState,
  type PropsWithChildren,
  type SelectHTMLAttributes,
} from 'react'

import type { AnyValue } from '@mntn-dev/utility-types'
import { type TestIds, forwardRef, getTestProps } from '../../utils'
import {
  type UseFormFieldControlProps,
  useFormFieldControl,
} from '../form-field'
import { SelectButton } from './select-button.tsx'
import { SelectOption } from './select-option.tsx'
import { SelectProvider, useSelect } from './use-select.ts'

type SelectProps = PropsWithChildren<
  Readonly<
    UseFormFieldControlProps<HTMLSelectElement> &
      Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> &
      TestIds & {
        placeholderText: string
        keepPlaceholderInList?: boolean
        filterMode?: 'internal' | 'external'
        onChange: (value: string, label?: string) => void
        onChangeQuery?: (query: string) => void
      }
  >
>

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      placeholderText,
      keepPlaceholderInList = false,
      children,
      onChange,
      onChangeQuery,
      dataTestId,
      dataTrackingId,
      disabled,
      filterMode = 'internal',
      id,
      hasError: hasErrorProp,
      hasSuccess: hasSuccessProp,
      hasWarning: hasWarningProp,
      readOnly,
      value,
      ...selectProps
    },
    ref
  ) => {
    const { hasError, hasSuccess, hasWarning, ...fieldProps } =
      useFormFieldControl<HTMLSelectElement>({
        dataTestId,
        dataTrackingId,
        disabled,
        id,
        hasError: hasErrorProp,
        hasSuccess: hasSuccessProp,
        hasWarning: hasWarningProp,
        readOnly,
      })

    const placeHolderOption = (
      <SelectOption
        value=""
        isPlaceholder={true}
        isHidden={!keepPlaceholderInList}
        label={placeholderText}
      />
    )

    const allOptions = React.Children.toArray(children).filter(
      React.isValidElement
    )
    allOptions.push(placeHolderOption)
    const [internallyManagedOptions, setInternallyManagedOptions] =
      useState(allOptions)
    const displayableOptions =
      filterMode === 'external' ? allOptions : internallyManagedOptions

    // label and icon are only provided to the option level (which are children). Find the currently
    // selected option and set the label and icon for display purposes in the button.
    const selectedOption = displayableOptions.find(
      (child): child is React.ReactElement =>
        // todo - fix this type and perhaps create better types for select/options in general
        (child as AnyValue).props.value === value
    ) as AnyValue

    const selectedOptionLabel = selectedOption?.props?.label || ''
    const selectedOptionIcon = selectedOption?.props?.icon || ''

    const selectQuery = (query: string) => {
      if (filterMode === 'internal') {
        const filteredOptions =
          query === ''
            ? allOptions
            : allOptions.filter((child): child is React.ReactElement =>
                (child as AnyValue).props.label
                  .toLowerCase()
                  .includes(query.toLowerCase())
              )

        setInternallyManagedOptions(filteredOptions)
      }

      onChangeQuery?.(query)
    }

    const context = useSelect({
      displayableOptions,
      placeholderText,
      selectedOptionIcon,
      selectedOptionLabel,
      selectQuery: selectQuery,
    })

    return (
      <SelectProvider value={context}>
        <Combobox
          ref={ref}
          {...selectProps}
          {...fieldProps}
          multiple={false}
          onChange={(newValue) => {
            if (value !== newValue) {
              onChange(String(newValue))
            }
          }}
          {...getTestProps({ dataTestId, dataTrackingId })}
        >
          {({ open }) => (
            <div className="relative">
              <SelectButton
                hasError={hasError}
                hasSuccess={hasSuccess}
                hasWarning={hasWarning}
                disabled={fieldProps.disabled}
                readOnly={fieldProps.readOnly}
                open={open}
              />
              <ComboboxOptions
                className={`absolute z-10 max-h-60 w-full overflow-auto text-sm shadow-lg ${themeBackgroundMap['container-secondary']} ${themeBackgroundBlurMap['blur-xl']}`}
              >
                <>
                  {placeHolderOption}
                  {children}
                </>
              </ComboboxOptions>
            </div>
          )}
        </Combobox>
      </SelectProvider>
    )
  }
)

const SelectNamespace = Object.assign(Select, {
  Option: SelectOption,
  Button: SelectButton,
})

export { SelectNamespace as Select, type SelectProps }
