import { useTranslation } from '@mntn-dev/i18n'
import { Text } from '@mntn-dev/ui-components'

export const EmptyPlaceholder = () => {
  const { t } = useTranslation('generic')

  return (
    <div className="w-full h-full">
      <Text
        className="h-full content-center text-center"
        as="div"
        textColor="tertiary"
      >
        {t('nothing-to-see')}
      </Text>
    </div>
  )
}
