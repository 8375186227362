import {
  FileExtensionSchema,
  archiveMimeTypes,
  videoMimeTypes,
} from '@mntn-dev/domain-types'

export const clientAllowedFormatsMap = {
  archive: Object.values(archiveMimeTypes)
    .flat()
    .map((ext) => ext.slice(1)),
  file: FileExtensionSchema.options.map((ext) => ext.slice(1)),
  video: Object.values(videoMimeTypes)
    .flat()
    .map((ext) => ext.slice(1)),
}
