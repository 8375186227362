import { type AnyUrn, FileId, OfferId, ProjectId } from '@mntn-dev/domain-types'
import { route } from './route.ts'

export const offerRoute = ({ offerId }: { offerId: OfferId | AnyUrn }) => {
  return route('/offers/:offerId').params({
    offerId: OfferId.parse(offerId),
  })
}

export const projectRoute = ({
  projectId,
}: { projectId: ProjectId | AnyUrn }) => {
  return route('/projects/:projectId').params({
    projectId: ProjectId.parse(projectId),
  })
}

export const projectFilesRoute = ({
  projectId,
}: { projectId: ProjectId | AnyUrn }) => {
  return route('/projects/:projectId/files').params({
    projectId: ProjectId.parse(projectId),
  })
}

export const projectFileRoute = ({
  projectId,
  fileId,
}: {
  projectId: ProjectId | AnyUrn
  fileId: FileId | AnyUrn
}) => {
  return route('/projects/:projectId/files/:fileId').params({
    projectId: ProjectId.parse(projectId),
    fileId: FileId.parse(fileId),
  })
}
