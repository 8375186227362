import { z } from 'zod'

import { OfferIdSchema, ProjectIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectOffersSentActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_offers_sent',
  z
    .object({
      projectId: ProjectIdSchema,
      offerIds: z.array(OfferIdSchema).nonempty(),
    })
    .merge(ActivityLinkTargets('project'))
)
